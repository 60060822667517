<template>
  <div class="survey_dashboard_container">
    <Statistic  group-name="mbsb"></Statistic>
    <div>
      <div class="survey_dashboard-main">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div style="padding-bottom: 10px">
                <p style="font-size: 12px">Эх сурвалж: Техникийн болон мэргэжлийн боловсрол, сургалтын салбарын <a
                  href="https://tvets.edu.mn/" target="_blank">tvets.edu.mn</a> мэдээллийн систем дэх 2021 оны төгсөгчийн тоон мэдээлэл 2021.12.31-ний өдрийн байдлаар</p>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12">
              <iframe src="https://analytic.edu-track.gov.mn/superset/dashboard/4/?standalone=true&native_filters=%28NATIVE_FILTER-2Pzv7zHC9%3A%28extraFormData%3A%28%29%2CfilterState%3A%28%29%2Cid%3ANATIVE_FILTER-2Pzv7zHC9%2CownState%3A%28%29%29%2CNATIVE_FILTER-Cpp25YvZY%3A%28extraFormData%3A%28%29%2CfilterState%3A%28%29%2Cid%3ANATIVE_FILTER-Cpp25YvZY%2CownState%3A%28%29%29%2CNATIVE_FILTER-LnBeVwq91%3A%28extraFormData%3A%28%29%2CfilterState%3A%28%29%2Cid%3ANATIVE_FILTER-LnBeVwq91%2CownState%3A%28%29%29%2CNATIVE_FILTER-VHuU3YDbm%3A%28extraFormData%3A%28%29%2CfilterState%3A%28%29%2Cid%3ANATIVE_FILTER-VHuU3YDbm%2CownState%3A%28%29%29%2CNATIVE_FILTER-Yvpdz7QP4%3A%28extraFormData%3A%28%29%2CfilterState%3A%28%29%2Cid%3ANATIVE_FILTER-Yvpdz7QP4%2CownState%3A%28%29%29%2CNATIVE_FILTER-Z1L5tgfJz%3A%28extraFormData%3A%28%29%2CfilterState%3A%28%29%2Cid%3ANATIVE_FILTER-Z1L5tgfJz%2CownState%3A%28%29%29%2CNATIVE_FILTER-cp4TBxL1e%3A%28extraFormData%3A%28%29%2CfilterState%3A%28%29%2Cid%3ANATIVE_FILTER-cp4TBxL1e%2CownState%3A%28%29%29%2CNATIVE_FILTER-jNQThIRaO%3A%28extraFormData%3A%28%29%2CfilterState%3A%28%29%2Cid%3ANATIVE_FILTER-jNQThIRaO%2CownState%3A%28%29%29%2CNATIVE_FILTER-naq1PeSQz%3A%28extraFormData%3A%28%29%2CfilterState%3A%28%29%2Cid%3ANATIVE_FILTER-naq1PeSQz%2CownState%3A%28%29%29%2CNATIVE_FILTER-xlXg2T9PF%3A%28extraFormData%3A%28%29%2CfilterState%3A%28%29%2Cid%3ANATIVE_FILTER-xlXg2T9PF%2CownState%3A%28%29%29%29"
                frameborder="0"
                style="border: none; width: 100%; height: 100vh"></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>

    <about></about>

  </div>
</template>

<script>
import { GET_ABOUT, GET_HOME_TEXT } from '../../graphql/queries'
import { getIdFromURL } from 'vue-youtube-embed'
import { mapGetters } from 'vuex'

const Statistic = () => import(/* webpackChunkName: "statistic" */ './statistic');
const about = () => import(/* webpackChunkName: "p_about" */ '../section/p_about')
export default {
  name: "tugsugch.vue",
  components: {
    "Statistic": Statistic,
    "about": about,
  },
  computed: {
    ...mapGetters([
      'languages',
      'language',
      // ...
    ]),
  },
  data () {
    return {

    }
  },
}
</script>

<style scoped>

</style>
